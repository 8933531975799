@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Changa&family=Montserrat&family=Palette+Mosaic&family=Unica+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: monospace;
  -webkit-backdrop-filter: blue(5px);
          backdrop-filter: blue(5px);
}
a {
  text-decoration: none;
}
.main-container {
  text-align: center;
}
.top {
  background: rgba(85, 166, 204, 0.5);
  background: rgb(20, 20, 20);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  overflow-x: hidden;
}
#name {
  padding: 20px;
  color: white;
  font-family: 'Unica One';
  font-size: 6em;
  -webkit-transform: translatex(-20%);
          transform: translatex(-20%);
}

.name-1__side {
  position: absolute;
  -webkit-transform: translateX(-200%);
          transform: translateX(-200%);
  opacity: 0.6;
  transition: all 2s cubic-bezier(0.37, 0.12, 0.1, 1.72);
}
.name-2__side {
  position: absolute;
  -webkit-transform: translateX(300%);
          transform: translateX(300%);
  opacity: 0.6;
  transition: all 3s cubic-bezier(0.37, 0.12, 0.1, 1.72);
}

.name-1__center {
  position: absolute;
  -webkit-transform: translateX(-20%);
          transform: translateX(-20%);
  opacity: 1;
  transition: all 2s cubic-bezier(0.37, 0.12, 0.1, 1.72);
  margin: 7%;
}
.name-2__center {
  position: absolute;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 1;
  transition: all 2s cubic-bezier(0.37, 0.12, 0.1, 1.72);
  margin: 7%;
}

.web-dev {
  color: white;
  position: relative;
  height: 150px;
  width: 50%;
  margin: 7%;
}
.web-dev span {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'Changa';
  font-size: 2em;
  -webkit-transform: translateX(-10%);
          transform: translateX(-10%);
  opacity: 0;
  transition: all 2s cubic-bezier(0.37, 0.12, 0.1, 1.72);
}
.web-dev__visible {
  opacity: 1!important;
}
#name__small {
  color: white;
  font-family: 'Unica One';
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg);
  font-size: 2em;
  margin: 40px 20px;
  height: 50px;
  width: 200px;
}
.menu-container {
  height: 250px;
  width: 50%;
  margin: 70px auto;
  display: flex;
}

.menu-container__small {
  height: 175px;
  width: 50%;
  margin: 20px auto;
  display: flex;
}

.menu-container__small .menu-item {
  font-size: 16px;
  border-radius: 60px;
  margin: 15px 10px;
  padding: 20px;
  height: 80px;
  min-width: 80px;
  max-width: 80px;
}
.menu-container__small .menu-item:hover {
  -webkit-transform: translateY(4%);
          transform: translateY(4%);
  background: rgb(41, 41, 41);
  border-radius: 50%;
  color: white;
  font-size: 1.2em;
}

.menu-item {
  font-size: 20px;
  margin: 20px 10px;
  padding: 30px;
  min-width: 200px;
  max-width: 200px; 
  width: 200px;
  background: rgb(243, 243, 243);
  transition: background-color .4s ease-in-out, border-radius .7s ease-in-out, -webkit-transform .6s cubic-bezier(0.37, 0.12, 0.1, 1.72);
  transition: transform .6s cubic-bezier(0.37, 0.12, 0.1, 1.72), background-color .4s ease-in-out, border-radius .7s ease-in-out;
  transition: transform .6s cubic-bezier(0.37, 0.12, 0.1, 1.72), background-color .4s ease-in-out, border-radius .7s ease-in-out, -webkit-transform .6s cubic-bezier(0.37, 0.12, 0.1, 1.72);
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 20px; */
}

.menu-item:hover {
  -webkit-transform: translateY(-15%);
          transform: translateY(-15%);
  /* border-radius: 20px; */
  /* border-radius: 0!important; */
  background: rgb(41, 41, 41);
  color: white;
  font-size: 2.5em;
  text-transform: uppercase;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#child1:hover {
  background: rgb(55, 104, 136);
}
#child2:hover {
  background: rgb(224, 129, 65);
} 
#child3:hover {
  background: rgb(150, 90, 147);
}
.info-section {
  border: 1px solid rgb(51, 51, 51);
  width: 65%;
  margin: 50px auto 0 auto;
  position: relative;
}
.info-section h1 {
  -webkit-backdrop-filter: blur(3px)!important;
          backdrop-filter: blur(3px)!important;
}
.bio-section h1 {
  background: rgba(55, 104, 136, 0.6)!important;
}
.portfolio-section h1 {
  background: rgba(224, 129, 65, 0.6)!important;
} 
.contact-section h1 {
  background: rgba(150, 90, 147, 0.6)!important;
}
.fade {
  opacity: 0
}
.bio-container {
  /* padding: 50px; */
  max-height: 450px;
  line-height: 1.7em;
  overflow-y: scroll;
  margin: 30px 0 0 0;
  overflow-x: hidden;
}
.cv-badge {
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightseagreen!important;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  top: -20px;
  right: -20px;
  transition: all .6s cubic-bezier(0.37, 0.12, 0.1, 1.72), background-color .4s ease-in-out;
}

.cv-badge:hover {
  background: rgb(36, 194, 186)!important;
}


.two-sides {
  display: flex;
  height: 1000px;
  justify-content: center;
}

.two-sides p {
  background: rgb(20, 20, 20);
  width: 75%;
  padding: 30px;
  margin-bottom: 30px;
  transition: all .3s ease-in;
  color: #FFF;
}
.two-sides img {
  max-height: 40px;
  max-width: 40px;
  margin: 4px;
}
.left, 
.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  /* width: 50% */
  width: 40%;
}
.left {
  border-right: 2px dashed black;
  padding-top: 50px;
}
.right {
  padding-top: 100px;
}
.test {
  border: 2px solid black
}
::-webkit-scrollbar-track
{
  background-color: transparent!important;
  box-shadow: none;
}
::-webkit-scrollbar
{
  width: 10px;
  background-color: rgb(250,250,250);
}
::-webkit-scrollbar-thumb
{
  background-color: rgb(51, 51, 51);

}
::-webkit-scrollbar-thumb:window-inactive {
    background: none;
}

.project-links {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  transition: all .5s ease-in-out;
}
.project-links a {
  transition: all .5s ease-in-out;
}
.project-links a:hover {
  background: black
}
.info-section a {
  background: rgb(88, 88, 88);
  color: white;
  padding: 15px;
}
.info-section h1 {
  min-width: 115px;
  max-width: 115px;
  font-family: 'Montserrat';
  background: rgb(51, 51, 51);
  color: white;
  padding: 10px 35px;
  transition: opacity 1.5s cubic-bezier(0.37, 0.12, 0.1, 1.72),
              -webkit-transform 2s cubic-bezier(0.37, 0.12, 0.83, 1.27);
  transition: transform 2s cubic-bezier(0.37, 0.12, 0.83, 1.27),
              opacity 1.5s cubic-bezier(0.37, 0.12, 0.1, 1.72);
  transition: transform 2s cubic-bezier(0.37, 0.12, 0.83, 1.27),
              opacity 1.5s cubic-bezier(0.37, 0.12, 0.1, 1.72),
              -webkit-transform 2s cubic-bezier(0.37, 0.12, 0.83, 1.27);
  opacity: 0;
}

.move-header {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 1!important;
}
.swiper-container {
  position: relative;
}
.swiper-button-prev, .swiper-button-next{
  padding: 10px;
  width: 40px;
  height: 40px;
  background: rgba(172, 172, 172, 0.596);
  border-radius: 40px;
  position:absolute;
  top: 50%;
  z-index: 600;
  -webkit-backdrop-filter: blur(3px)!important;
          backdrop-filter: blur(3px)!important;
}
.swiper-button-prev {
 left: 0
}
.swiper-button-next {
 right: 0;
}
.swiper-button-prev::before {
  content: '«';
}
.swiper-button-next::after {
  content: '»'
}
.swiper-button-prev::before, .swiper-button-next::after {
  font-size: 2.2em;
  color: white;
}
.swiper-button-prev:hover, .swiper-button-next:hover {
  cursor: pointer
}
.swiper-button-disabled {
  display: none;
}

.project-container {
  padding: 20px;
  background: rgb(231, 231, 231);
  border: 1px solid rgb(114, 114, 114);
  margin: 20px!important;
  height: 400px;
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.project-description {
  font-size: 1.1em;
  margin: 15px;
}
.contact-container {
  margin: 60px auto;
  display: flex;
  justify-content: space-evenly;
}

.contact-container div {
  margin: 20px;
  transition: all .4s cubic-bezier(0.37, 0.12, 0.1, 1.72);
}

.contact-container div:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}

#linkedin:hover {
  -webkit-filter: invert(14%) sepia(99%) saturate(4578%) hue-rotate(230deg) brightness(102%) contrast(109%);
          filter: invert(14%) sepia(99%) saturate(4578%) hue-rotate(230deg) brightness(102%) contrast(109%)
}

#instagram:hover {
  -webkit-filter: invert(28%) sepia(74%) saturate(1828%) hue-rotate(281deg) brightness(90%) contrast(94%);
          filter: invert(28%) sepia(74%) saturate(1828%) hue-rotate(281deg) brightness(90%) contrast(94%);
}

#email:hover {
  -webkit-filter: invert(88%) sepia(36%) saturate(1562%) hue-rotate(345deg) brightness(106%) contrast(102%);
          filter: invert(88%) sepia(36%) saturate(1562%) hue-rotate(345deg) brightness(106%) contrast(102%);
}
/* 
@media screen and (max-width: ) */
@media screen and (max-width: 1300px) {

  .menu-container .menu-item {
    padding: 0;
  }

  .menu-container__small {
    margin: 0;
  }
  .move-header {
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }

  .left, 
  .right {
  width: 50% 
  }
}

@media screen and (max-width: 960px) {

  .menu-container {
    width: 100%;
    justify-content: space-evenly;
  }

  .menu-container .menu-item {
    padding: 0;
  }

  .top {
    width: 100%;
    position:relative;
    z-index: 100;
    position: fixed;
    top: 0;
  }
  .menu-container__small {
    margin: 0;
  }

  .info-section {
    margin-top: 250px;
  }
  .move-header {
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }
}

@media screen and (max-width: 768px) {

  .menu-container__small {
    height: 150px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .menu-container__small .menu-item {
    font-size: 14px;
    border-radius: 60px;
    margin: 15px 10px;
    padding: 15px;
    height: 60px;
    min-width: 60px;
    max-width: 60px;
  }

  .info-section {
    width: 80%;
    margin: 200px auto 0 auto;
    height: 60vh;
    /* overflow: hidden; */
  }
  .bio-section {
    overflow: hidden
  }
  .move-header {
    -webkit-transform: none;
            transform: none;
  }
  .bio-container {
    max-height: 80vh;
  }
  .left, 
  .right {
  align-items: flex-start!important;
  justify-content: flex-start;
}
  .contact-container {
    display: flex;
    flex-direction: column;
  }
  .contact-container img {
    height: 60px;
  }
}

@media screen and (max-width: 680px)  {
  #name {
    font-size: 3em;
  }
  .web-dev {
    height: 80px;
  }
  .web-dev span {
    font-size: 1.3em;
  }
  .menu-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
  }
  .menu-container .menu-item {
    width: 40px;
    height: 60px;
  }
  .menu-container__small .menu-item {
    font-size: 12px;
    border-radius: 50px;
    margin: 15px 10px;
    padding: 10px;
    height: 50px;
    min-width: 50px;
    max-width: 50px;
  }

  .two-sides p {
    width: 95%!important;
    padding: 12px 7px;
  }
  .project-container {
    padding: 0;
    margin: 40px 0!important;
    height: 400px;
    width: 100%;
  }
}
@media screen and (max-width: 480px) {

  html {
    overflow: scroll;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
      width: 0;  
      background: transparent; 
  }

  ::-webkit-scrollbar-thumb {
      background: #FF0000;
  }

  .menu-container__small {
    height: 120px!important;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .move-header {
    display: none;
  }
  .info-section {
    width: 100%;
    height: 80vh;
    margin: 120px 0 0 0;
    border: none;
  }
  #name__small {
    font-size: 1.5em;
    margin: 20px 10px;
    height: 40px;
    width: 150px;
  }

  .left {
    margin-top: 60px;
    border-right: none;
    padding-left: 5px;
  }
  .right {
    margin-top: 90px;
  }
  .two-sides p {
    width: 90%!important;
    padding: 8px 5px;
  }
  .project-container {
    padding: 0;
    /* margin: 40px 0; */
    height: 400px;
    width: 100%;
  }
  .contact-container {
    margin: 40px 0;
  }
  .contact-container > div > img {
    max-height: 50px;
  }
}

@media screen and (max-width: 420px) {
  .menu-container__small .menu-item {
    font-size: 10px;
    border-radius: 50px;
    margin: 15px 10px;
    padding: 7px;
    height: 50px;
    min-width: 50px;
    max-width: 50px;
  }
  .left {
    margin-top: 0;
    padding-top: 15px;
  }
  .right {
    margin-top: 0;
    padding-top: 60px;
  }
}
